.App {
  text-align: center;
}

.BMI-Chart td.right {
  text-align: right;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.BMI-Chart td.left {
  text-align: left;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header p {
  width: 100%;
}

.form td {
  padding-right: 0.5em;
  text-align: left;
}

.form td.right {
  text-align: right;
}

.plan td {
  text-align: right;
  padding: 0.2em;
}